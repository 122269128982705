export const DASHBOARD_FILTER_PROVINCE = -1
export const DASHBOARD_FILTER_AGE = -2
export const DASHBOARD_FILTER_GENDER = -3

export const TESTER_STATUS_PADAWAT = 'REGULAR'
export const TESTER_STATUS_WATPRO = 'PRO'
export const TESTER_STATUS_WATGURU = 'GURU'

export const TEST_TYPE_PRODUCT = 'PRODUCT_TEST'
export const TEST_TYPE_WEB = 'WEB_TEST'
export const TEST_TYPE_APP = 'APP_TEST'
export const TEST_TYPE_GEO = 'GEO_TEST'

export const COMMENT_REACTION_NONE = 0
export const COMMENT_REACTION_LIKE = 1
export const COMMENT_REACTION_DISLIKE = 2

export const ACTIVITY_TYPE_BLOG = 'WAT_BLOG'
export const ACTIVITY_TYPE_SURVEY = 'WAT_SURVEY'
export const ACTIVITY_TYPE_CHAT = 'WAT_CHAT'

export const SURVEY_QUESTION_TEXT = 'TEXT'
export const SURVEY_QUESTION_SELECTION = 'SELECTION'
export const SURVEY_QUESTION_MULTISELECTION = 'MULTISELECTION'
export const SURVEY_QUESTION_RANKING = 'RANKING'
export const SURVEY_QUESTION_RANKING_STARS = 'STARS'

export const EL_MEDAL        = 'EL_NEW_MEDAL'
export const EL_ANSWER       = 'EL_NEW_ANSWER_TO_COMMENT'
export const EL_MOD_NEW_ANSWER_TO_COMMENT = 'EL_MOD_NEW_ANSWER_TO_COMMENT'
export const EL_ACT_REMINDER = 'EL_COMMUNITY_ACTIVIY_REMINDER'
export const EL_ACT_LAUNCHED = 'EL_COMMUNITY_ACTIVIY_LAUNCHED'
export const EL_MOD_COMMUNITY_ACTIVIY_LAUNCHED = 'EL_MOD_COMMUNITY_ACTIVIY_LAUNCHED'
export const EL_COM_LAUNCHED = 'EL_COMMUNITY_LAUNCHED'
export const EL_MENTION = 'EL_USER_MENTIONED_IN_COMMENT'
export const EL_MOD_MENTIONED_IN_COMMENT = 'EL_MOD_MENTIONED_IN_COMMENT'
export const EL_NEW_ANSWER_TO_COMMENT = 'EL_NEW_ANSWER_TO_COMMENT'
export const WL_MEDAL        = 'WL_NEW_MEDAL'
export const WL_ANSWER       = 'WL_NEW_ANSWER_TO_COMMENT'
export const WL_ACT_REMINDER = 'WL_COMMUNITY_ACTIVIY_REMINDER'
export const WL_ACT_LAUNCHED = 'WL_COMMUNITY_ACTIVIY_LAUNCHED'
export const WL_MOD_COMMUNITY_ACTIVIY_LAUNCHED = 'WL_MOD_COMMUNITY_ACTIVIY_LAUNCHED'
export const WL_COM_LAUNCHED = 'WL_COMMUNITY_LAUNCHED'
export const WL_MENTION = 'WL_USER_MENTIONED_IN_COMMENT'
export const WL_MOD_MENTIONED_IN_COMMENT = 'WL_MOD_MENTIONED_IN_COMMENT'
export const WL_NEW_ANSWER_TO_COMMENT = 'WL_NEW_ANSWER_TO_COMMENT'
export const WL_MOD_NEW_ANSWER_TO_COMMENT = 'WL_MOD_NEW_ANSWER_TO_COMMENT'

// Speeds for intervals/timeouts.
export const DEBOUNCE_SPEED         = 300;
export const RELOAD_NOTIF_INTERVAL  = 60000;

export const FIGMA_CLIENT_ID = '3AyjPvVIuRryH6bc8se2Dz'